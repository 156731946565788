/*
-----------------------------------------------------------------------
	About CSS
-----------------------------------------------------------------------
*/
$color_1: #000;
$background-color_1:#7C2869;


@-webkit-keyframes opacity {
	0%,100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}
@keyframes opacity {
	0%,100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}
@keyframes out {
	0% {
		r: 1;
		opacity: 0.9;
	}
	25% {
		r: 5;
		opacity: 0.3;
	}
	50% {
		r: 10;
		opacity: 0.2;
	}
	75% {
		r: 15;
		opacity: 0.1;
	}
	100% {
		r: 20;
		opacity: 0;
	}
}
.page_404 {
	display: flex;
	min-height: 70vh;
	justify-content: center;
	align-items: center;
}
path#XMLID_5_ {
	fill: #7C2869;
	filter: #7C2869;
}
path#XMLID_11_ {
	fill: #7C2869;
}
path#XMLID_2_ {
	fill:#7C2869;
}
.circle {
	animation: out 2s infinite ease-out;
	fill: #7C2869;
}
#container_404 {
	text-align: center;
}
.message_404 {
	color: #7C2869;
	&:after {
		content: "]";
		color: #7C2869;
		font-size: 20px;
		-webkit-animation-name: opacity;
		-webkit-animation-duration: 2s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-name: opacity;
		animation-name: opacity;
		-webkit-animation-duration: 2s;
		animation-duration: 2s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		margin: 0 50px;
	}
	&:before {
		content: "[";
		color: #7C2869;
		font-size: 20px;
		-webkit-animation-name: opacity;
		-webkit-animation-duration: 2s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-name: opacity;
		animation-name: opacity;
		-webkit-animation-duration: 2s;
		animation-duration: 2s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		margin: 0 50px;
	}
}


#section-tab {
	padding: 1rem;
	display: flex;
}
#tab-control {
	padding: 1rem;
	width: 50%;
}
#tab-div {
	padding: 1rem;
	width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acc_sss{
	padding: 1rem;
	// border: 1px solid #0000003d;
	margin-bottom: 1rem;
	cursor: pointer;
	font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 
}
video{
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
 .acc_sss{
	list-style: none;
}
#control-selected {
	background-color: #7C2869e8;
	color: $color_1;
}
.buyuk{
margin-top:10vh;
  @media #{$lg-device} {
   display: block;
  }
  @media #{$sm-device} {
   display: none;
  }
}
.kucuk{
  margin-top: 6vh;
  @media #{$xl-device} {
    display: none;
    }
  @media #{$lg-device} {
  display: none;
  }
  @media #{$sm-device} {
    display: block;
  }
}
.about-area {
  padding: 25px 0px;
  border-bottom: 1px solid #e5e5e5;
  @media #{$lg-device} {
    padding: 90px 0px;
  }
  @media #{$sm-device} {
    padding:70px 0px;
  }
  .text-pra {
    color: $black;
    font-size: 36px;
    font-weight: $font-weight-light;
    line-height: 48px;
    letter-spacing: -.10px;
    margin: 0 auto 125px;
    max-width: 830px;
    padding-right: 50px;
    @media #{$lg-device} {
      margin-bottom: 85px;
    }
    @media #{$sm-device} {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 50px;
      padding-right: 0;
    }
  }
  .service-list-content {
    margin: 0 auto 62px;
    max-width: 830px;
    @media #{$lg-device} {
      margin-bottom: 40px;
    }
    @media #{$sm-device} {
      margin-bottom: 20px;
    }
    .title {
      margin-bottom: 41px;
  
      @media #{$sm-device} {
        margin-bottom: 30px;
      }
    }
    p {
      color: $black-999;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom:34px;
      @media #{$sm-device} {
        margin-bottom: 40px;
      }
      &.text-block {
        color: $black;
        font-size: 16px;
        margin-bottom: 31px;
      }
    }
    .service-list {
      display: flex;
      justify-content: space-between;
      @media #{$xxs-device} {
        display: block;
        padding-bottom: 20px;
      }
      ul {        
        @media #{$xxs-device} {
          margin-bottom: 0;
        }
        li {
          margin-bottom: 24px;
          @media #{$sm-device} {
            margin-bottom: 18px;
          }
          a {
            color: $black;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1;
     
            @media #{$sm-device} {
              font-size: 14px;
            }
            &:hover {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
  .office-center-content {
    .title {
      margin: 28px auto 28px;
      max-width: 830px;
   
      @media #{$sm-device} {
        margin-bottom: 20px;
      }
    }
    p {
      color: $black-999;
      letter-spacing: 0;
      line-height: 30px;
      margin: 0 auto 17px;
      max-width: 830px;
      @media #{$sm-device} {
        margin-bottom: 40px;
      }
    }
    .office-address-content {
      display: flex;
      justify-content: space-between;
      margin: 45px auto 0;
      max-width: 830px;
      padding-right: 30px;
      @media #{$lg-device} {
        margin-top: 95px;
      }
      @media #{$sm-device} {
        margin-top: 80px;
      }
      @media #{$xxs-device} {
        display: flex;
        margin-top: 65px;
      }
      .office-address-item {
        h5 {
          font-size: 18px;
          letter-spacing: -.21px;
          margin-bottom: 23px;
        
        }
        ul {
          margin-bottom: 30px;
          li {
            color: $black-999;
            font-size: 16px;
            margin-bottom: 3px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}